import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const CompanyStressCheckWorkBarChart = ({
  stressCheckEvent,
  lastStressCheckEvent,
}) => {
  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        position: 'top',
        labels: {
          boxWidth: 0,
          boxHeight: 0,
        },
      },
    },
    scales: {
      y: {
        min: 20,
        max: 80,
      }
    },
  }

  const labels = [
    '量的負担',
    '質的負担',
    '身体負担',
    '対人関係',
    '職場環境',
    'コントロール',
    '技能活用',
    '適正度',
    '働きがい'
  ]

  const deviationColor = (deviation) => {
    // ・偏差値54以上の項目を青、46以下を赤、それ以外をオレンジ
    if (deviation >= 54) {
      return '#349eed'
    } else if (deviation <= 46) {
      return '#f87171'
    } else {
      return '#f59e0b'
    }
  }

  const lastStressCheckEventName = lastStressCheckEvent?.name || ''

  const data = {
    labels,
    datasets: [
      {
        label: stressCheckEvent.name,
        animation: false,
        data: [
          stressCheckEvent.work1PointDeviation,
          stressCheckEvent.work2PointDeviation,
          stressCheckEvent.work3PointDeviation,
          stressCheckEvent.work4PointDeviation,
          stressCheckEvent.work5PointDeviation,
          stressCheckEvent.work6PointDeviation,
          stressCheckEvent.work7PointDeviation,
          stressCheckEvent.work8PointDeviation,
          stressCheckEvent.work9PointDeviation,
        ],
        backgroundColor: [
          deviationColor(stressCheckEvent.work1PointDeviation),
          deviationColor(stressCheckEvent.work2PointDeviation),
          deviationColor(stressCheckEvent.work3PointDeviation),
          deviationColor(stressCheckEvent.work4PointDeviation),
          deviationColor(stressCheckEvent.work5PointDeviation),
          deviationColor(stressCheckEvent.work6PointDeviation),
          deviationColor(stressCheckEvent.work7PointDeviation),
          deviationColor(stressCheckEvent.work8PointDeviation),
          deviationColor(stressCheckEvent.work9PointDeviation),
        ],
      },
      {
        label: lastStressCheckEventName,
        animation: false,
        data: [
          lastStressCheckEvent.work1PointDeviation,
          lastStressCheckEvent.work2PointDeviation,
          lastStressCheckEvent.work3PointDeviation,
          lastStressCheckEvent.work4PointDeviation,
          lastStressCheckEvent.work5PointDeviation,
          lastStressCheckEvent.work6PointDeviation,
          lastStressCheckEvent.work7PointDeviation,
          lastStressCheckEvent.work8PointDeviation,
          lastStressCheckEvent.work9PointDeviation,
        ],
        backgroundColor: '#ddd',
      },
    ],
  }

  return <Bar options={options} data={data} />;
}

export default CompanyStressCheckWorkBarChart
