import React, { useState } from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import ReserveEvents from './ReserveEvents'
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const CounselorReserveEvents = ({ reserves, choiceGenderId }) => {
  const events = reserves.map((event, index) => {
    return {
      id: event.id,
      start: event.start_at,
      end: event.end_at,
      display: 'background',
      counselorId: event.counselor_id,
      advisorId: event.advisor_id
    }
  })

  const handleEventClick = (event) => {
    let reserveUrl = `/users/my_page/counselors/${event.counselorId}/counseling_reserves/${event.id}/`
    if (choiceGenderId) {
      reserveUrl = `${reserveUrl}?choice_gender_id=${choiceGenderId}`
    }

    location.href = reserveUrl
  }

  return (
    <>
      <ReserveEvents events={events} handleEventClick={handleEventClick} />
    </>
  )
}

export default CounselorReserveEvents
