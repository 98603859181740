import React, { useState } from 'react'
import Calendar from './Calendar'
import Modal from 'react-modal'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.85)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9998
  },
  content: {
    position: "absolute",
    width: "90%",
    height: "auto",
    inset: "initial",
    borderRadius: "1rem",
    padding: "1.5rem",
    zIndex: 9999
  }
}

const AdvisorCalendar = ({ reserves }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectEvent, setSelectEvent] = useState({})

  const handleEventClick = (event) => {
    location.href = `/users/my_page/advisors/${event.advisorId}/advisor_reserves/${event.id}/`
    //setModalIsOpen(true)
    //setSelectEvent(event)
  }

  //const handleReserveClick = () => {
  //  location.href = `/users/my_page/advisors/${selectEvent.advisorId}/advisor_reserves/${selectEvent.id}/`
  //}

  return (
    <>
     {/*<Modal
        style={modalStyle}
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <div className="h-full mt-4">
          <div className="font-bold text-center text-2xl flex justify-center border-b-2 pb-6">
            <h2>{selectEvent.title}</h2>
          </div>

          <div className="mt-6">
            <div className="text-center text-lg font-bold">
              {dayjs(selectEvent.start).format('YYYY年MM月DD日')}
            </div>
            <div className="text-center text-2xl font-bold mt-4">{dayjs(selectEvent.start).format('HH:mm')} ~ {dayjs(selectEvent.end).format('HH:mm')}</div>
          </div>
          <div className="form flex justify-center items-center mt-8">
            <button onClick={handleReserveClick} className="bg-yellow-500 text-white block w-full mx-auto lg:w-1/3 flex items-center justify-center p-4 border-b-2 border-yellow-600 rounded-xl text-center text-lg font-bold">
              <span className="">この日付で<br className="md:hidden" />予約に進む</span>
            </button>
          </div>

          <button className="block mx-auto underline mt-6 font-bold text-center" onClick={() => setModalIsOpen(false)}>✕ 閉じる</button>
        </div>
      </Modal>*/}

      <Calendar
        events={reserves}
        handleEventClick={handleEventClick}
      />
    </>
  )
}

export default AdvisorCalendar
