import React, { useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import jaLocale from '@fullcalendar/core/locales/ja'
import interactionPlugin from '@fullcalendar/interaction'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import ReserveEvents from './ReserveEvents'

dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const Calendar = ({ events, handleEventClick }) => {
  const [selectDayEvents, setSelectDayEvents] = useState([])
  const calendarEvents = events.map((event, index) => {
    return {
      id: event.id,
      title: '予約空枠',
      start: event.start_at,
      end: event.end_at,
      counselorId: event.counselor_id,
      advisorId: event.advisor_id
    }
  })
  const viewEvents = events.map((event, index) => {
    return {
      id: event.id,
      start: dayjs(event.start_at).tz().format('YYYY-MM-DD'),
      end: dayjs(event.end_at).tz().format('YYYY-MM-DD'),
      display: 'background',
      counselorId: event.counselor_id,
      advisorId: event.advisor_id
    }
  })

  const now = dayjs().add(1, "day").tz().format('YYYY-MM-DD')
  const three_month_later = dayjs().tz().add(3, "month").endOf('month').format('YYYY-MM-DD')

  const handleDateClick = (info) => {
    const events = getDateEvent(info.date)
    setSelectDayEvents(events)
    location.href = '#reserves'
  }

  const getDateEvent = (day) => {
    const startDate = dayjs(day).hour(0).minute(0).format()
    const endDate = dayjs(day).hour(23).minute(59).format()

    return calendarEvents.map((event, index) => {
      const eventStartDate = dayjs(event.start).hour(0).minute(0).format()
      const eventEndDate = dayjs(event.end).hour(23).minute(59).format()
      // 日付の一致を確認
      if (startDate === eventStartDate && endDate === eventEndDate) {
        return event
      }
    }).filter(e => e)
  }

  const hasEvents = (date) => {
    const events = getDateEvent(date)
    return events && (events.length > 0)
  }

  const eventCounts = (date) => {
    const events = getDateEvent(date)
    if (!events) return 0
    return events.length
  }

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
        contentHeight='auto'
        initialView="dayGridMonth"
        slotMinTime='09:00:00'
        slotMaxTime='22:00:00'
        slotDuration='00:15:00'
        slotLabelInterval='00:30'
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short'
        }}
        locales={[jaLocale]}
        locale='ja'
        //eventClick={handleDateClick}
        dateClick={handleDateClick}
        validRange={{
          start: now,
          end: three_month_later
        }}
        headerToolbar={{
          left: 'title',
          right: 'prev,next',
        }}
        events={viewEvents}
        views={{
          dayGridMonth: {
            dayCellDidMount: function (info) {
              //console.log(info)
              //if (hasEvents(info.date)) info.el.classList.add('fc-day-disabled');
            },
            dayCellContent: function (info, createElement) {
              const eventLength = eventCounts(info.date)
              if (eventLength > 0) {
                const markText = eventLength > 3 ? '◯' : '△'
                const markColor = eventLength > 3 ? '#fd5353' : '#1cac09'

                const dateElement = createElement(
                  'span',
                  {
                    style: {
                      fontWeight: 'bold',
                      fontStyle: 'normal',
                      right: "10%",
                    }
                  },
                  info.dayNumberText,
                )
                const markElement = createElement(
                  'div',
                  {
                    className: 'fc-daygrid-day-marker',
                    style: {
                      color: markColor,
                      textAlign: 'center',
                      fontStyle: 'normal',
                      fontWeight: 'bold',
                      position: 'relative',
                    }
                  },
                  markText
                )

                return [dateElement, markElement]
              }
            },
            dayCellClassNames: function (info) {
              //if (hasEvents(info.date)) return ['fc-day-disabled']
            }
          }
        }}
      />
      <ReserveEvents events={selectDayEvents} handleEventClick={handleEventClick} />
    </>
  )
}

export default Calendar
