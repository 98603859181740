import React from 'react'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

import ReserveForm from './ReserveForm'

const StripeReserveForm = ({ stripePublicKey }) => {
  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(stripePublicKey)

  const options = {
    appearance: {/*...*/},
  }

  return (
    <Elements stripe={stripePromise} options={options}>
      <ReserveForm />
    </Elements>
  )
}

export default StripeReserveForm
