import React, {useState} from 'react'
import Modal from 'react-modal'

const StressCheckAnswerForm = ({ stressCheckQuestions, stressCheckEventId }) => {
  const [isCompleted, setIsCompleted] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalText, setModalText] = useState('回答を送信中です...')
  const [errorMessage, setErrorMessage] = useState(null)

  // カテゴリ、サブ質問ごとに質問をグルーピングする
  const groupedQuestions = JSON.parse(stressCheckQuestions).reduce((groupedQuestions, question) => {
    const category = question.stress_check_question_category
    const subQuestion = question.stress_check_sub_question

    if (!groupedQuestions[category.id]) {
      groupedQuestions[category.id] = {
        id: category.id,
        code: category.code,
        SubQuestions: {},
      }
    }

    if (subQuestion) {
      if (!groupedQuestions[category.id].SubQuestions[subQuestion.id]) {
        groupedQuestions[category.id].SubQuestions[subQuestion.id] = {
          id: subQuestion.id,
          title: question.stress_check_sub_question.title,
          questions: [],
        }
      }
      groupedQuestions[category.id].SubQuestions[subQuestion.id].questions.push(question)
    } else {
      if (!groupedQuestions[category.id].SubQuestions['']) {
        groupedQuestions[category.id].SubQuestions[''] = {
          id: '',
          title: '',
          questions: [],
        }
      }
      groupedQuestions[category.id].SubQuestions[''].questions.push(question)
    }

    return groupedQuestions
  }, {})
  // グルーピングした質問をソートする
  const sortGroupedQuestions = Object.values(groupedQuestions).sort((a, b) => {
    if (a.id < b.id) return -1
    if (a.id > b.id) return 1
    return 0
  }).map((category) => {
    category.SubQuestions = Object.values(category.SubQuestions).sort((a, b) => {
      if (a.id < b.id) return -1
      if (a.id > b.id) return 1
      return 0
    })
    return category
  })

  const [categoryQuestions, setCategoryQuestions] = useState(sortGroupedQuestions)
  const [questions, setQuestions] = useState(categoryQuestions.map((category) => {
    return category.SubQuestions.map((subQuestion) => {
      return subQuestion.questions.map((question) => {
        return {
          id: question.id,
          answerNumber: null,
          categoryID: category.id,
          subQuestionID: subQuestion.id,
        }
      })
    })
  }).flat(2))

  const [categoryAnswers, setCategoryAnswers] = useState([
    {
      work: {
        title: 'A. あなたの仕事についてうかがいます。最もあてはまるものに○を付けてください。',
        colorName: 'red',
        answers: [
          'そうだ',
          'まあそうだ',
          'ややちがう',
          'ちがう',
        ],
      },
      state: {
        title: 'B. 最近1か月間のあなたの状態についてうかがいます。最もあてはまるものに○を付けてください。',
        colorName: 'blue',
        answers: [
          'ほとんどなかった',
          'ときどきあった',
          'しばしばあった',
          'ほとんどいつもあった',
        ]
      },
      around: {
        title: 'C. あなたの周りの方々についてうかがいます。最もあてはまるものに○を付けてください。',
        colorName: 'green',
        answers: [
          '非常に',
          'かなり',
          '多少',
          '全くない',
        ],
      },
      satisfaction: {
        title: 'D. 満足度について',
        colorName: 'yellow',
        answers: [
          '満足',
          'まあ満足',
          'やや不満足',
          '不満足',
        ]
      }
    }
  ])

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1)

  const onChangeAnswer = (event) => {
    const questionNumber = parseInt(event.target.dataset.questionNumber, 10)
    const answerNumber = parseInt(event.target.value, 10)

    const newQuestions = questions.map((question, index) => {
      if (question.id === questionNumber) {
        return {
          ...question,
          answerNumber: answerNumber,
        }
      }
      return question
    })
    setQuestions(newQuestions)

    if (questions.length === questionNumber) {
      // 最後の質問の場合
      setCurrentQuestionNumber(questionNumber)
    } else if (currentQuestionNumber !== questionNumber) {
      // 現在の質問番号と選択した質問番号が異なる場合は、選択した質問番号に変更する
      setCurrentQuestionNumber(questionNumber + 1)
    } else if (currentQuestionNumber <= questions.length - 1) {
      setCurrentQuestionNumber(currentQuestionNumber + 1)
    } else {
      setIsCompleted(true)
    }

    // 現在の質問にスムーススクロールする
    const currentQuestion = document.getElementById(`question_${questionNumber}`)
    currentQuestion.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const isValid = () => {
    setErrorMessage(null)
    
    // 未回答の質問があるかどうかチェックする
    const unansweredQuestions = questions.filter((question) => {
      return question.answerNumber === null
    })
    if (unansweredQuestions.length > 0) {
      setErrorMessage('未回答の質問があります')
      return false
    }

    return true
  }

  const handleSubmit = (event) => {
    if (!isValid()) return

    event.preventDefault()

    // questionsをq1...q57の形式でanswerNumberを取り出し、paramsにセットする
    const params = {
      stress_check_answer: {},
    }
    questions.forEach((question) => {
      params['stress_check_answer'][`q${question.id}`] = question.answerNumber
    })

    // railsのcsrf tokenをセットする
    const csrfToken = document.querySelector('[name=csrf-token]').content
    const eventId = Number(stressCheckEventId)

    // formを生成し、postリクエストを送る
    // `/users/my_page/stress_check_events/${eventId}/stress_check_answers`にPOSTリクエストを送る
    const form = document.createElement('form')
    form.method = 'POST'
    form.action = `/users/my_page/stress_check_events/${eventId}/stress_check_answers`
    form.style.display = 'none'
    document.body.appendChild(form)

    const csrfInput = document.createElement('input')
    csrfInput.type = 'hidden'
    csrfInput.name = 'authenticity_token'
    csrfInput.value = csrfToken
    form.appendChild(csrfInput)

    questions.forEach((question) => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = `stress_check_answer[q${question.id}]`
      input.value = question.answerNumber + 1
      form.appendChild(input)
    })

    form.submit()

    setModalIsOpen(true)
  }

  return (
    <>
      <Modal
        style={{
          overlay: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#000000a8',
            alignItems: 'center',
            zIndex: '100'
          },
          content: {
            position: 'absolute',
            width: '80%',
            margin: '15% auto',
            padding: '0',
            inset: '0',
            background: '#f7fbfc',
            overflow: 'auto',
            FontSize: '14px',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '20px'
          }
        }}
        isOpen={modalIsOpen}
      >
        <div className="flex h-full items-center flex-wrap">
          <div className="text-center w-full mx-auto text-xl md:text-2xl font-bold">
            {modalText}
          </div>
        </div>
      </Modal>

      <div className="w-full mt-4 md:p-8">
        {categoryQuestions.map((category, categoryIndex) => {
          const currentCategoryID = questions[currentQuestionNumber - 1]['categoryID']
          return (
            <>
              <div className={`text-lg bg-${categoryAnswers[0][category.code].colorName}-400 text-white font-bold p-8 transition-opacity duration-300 ${currentCategoryID === category.id ? 'opacity-100' : 'opacity-30'} hover:opacity-100`} key={categoryIndex}>
                {categoryAnswers[0][category.code].title}
              </div>
              {category.SubQuestions.map((subQuestion, subIndex) => {
                const currentSubQuestionID = questions[currentQuestionNumber - 1]['subQuestionID']
                return (
                  <>
                    {subQuestion.title && (
                      <div className={`text-base bg-gray-100 font-bold p-8 py-4 transition-opacity duration-300 ${currentSubQuestionID === subQuestion.id ? 'opacity-100' : 'opacity-30'} hover:opacity-100`} key={subIndex}>
                        {subQuestion.title}
                      </div>
                    )}
                    {subQuestion.questions.map((question, index) => {
                      return (
                        <div key={question.id} className={`w-full p-8 transition-opacity duration-300 ${currentQuestionNumber === question.id ? 'opacity-100 shadow-lg' : 'opacity-30'} hover:opacity-100`} id={`question_${question.id}`}>
                          <p className="text-base font-bold">
                            {index + 1}. {question.title}
                          </p>
                          <div className="w-full mt-4">
                            <ul className="md:flex items-center justify-center w-full">
                              {categoryAnswers[0][question.stress_check_question_category.code].answers.map((answer, answerIndex) => {
                                return (
                                  <li className="w-full md:w-1/4" key={answerIndex}>
                                    <input
                                      id={`answer_${question.id}_${answerIndex}`}
                                      type="radio"
                                      value={answerIndex}
                                      data-question-number={question.id}
                                      className="hidden"
                                      onChange={onChangeAnswer}
                                      checked={questions[question.id - 1].answerNumber === answerIndex}
                                    />
                                    <label htmlFor={`answer_${question.id}_${answerIndex}`} className={`p-4 text-sm text-center border border-gray-100 w-full hover:bg-${categoryAnswers[0][category.code].colorName}-300 cursor-pointer block bg-white transition-colors duration-300 ${questions[question.id - 1].answerNumber === answerIndex && `bg-${categoryAnswers[0][category.code].colorName}-300`}`}>
                                      {answer}
                                    </label>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )
              })}
            </>
          )
        })}

        {errorMessage && <div className="mt-6 text-red-600 font-bold text-lg">※{errorMessage}</div>}

        <span className="hidden bg-red-300 hover:bg-red-300 bg-blue-300 hover:bg-blue-300 bg-green-300 bg-green-400 hover:bg-green-300 bg-yellow-300 hover:bg-yellow-300"></span>

        <div className="w-full mt-12">
          <button
            onClick={handleSubmit}
            className={`w-full p-4 bg-red-100 hover:bg-red-400 text-white rounded-lg transition-colors duration-300 bg-red-400`}
          >
            回答を送信する
          </button>
        </div>
      </div>
    </>
  )
}

export default StressCheckAnswerForm
