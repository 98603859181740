import React, {useState,useEffect} from 'react'
import Modal from 'react-modal'

const FormButton = ({ formId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalText, setModalText] = useState('処理中です...。このまましばらくお待ちください。')
  const [errorMessage, setErrorMessage] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()

    setButtonDisabled(true)
    const form = document.getElementById(formId)
    form.reportValidity()

    if (form.checkValidity()) {
      setModalIsOpen(true)
      form.submit()
    } else {
      setModalIsOpen(false)
      setButtonDisabled(false)
    }
  }

  return (
    <div className="border-t py-6 border-gray-300">
      <Modal
        style={{
          overlay: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#000000a8',
            alignItems: 'center',
            zIndex: '100'
          },
          content: {
            position: 'absolute',
            width: '60%',
            margin: '15% auto',
            padding: '0',
            inset: '0',
            background: '#f7fbfc',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '20px'
          }
        }}
        isOpen={modalIsOpen}
      >
        <div className="flex h-full items-center flex-wrap">
          <div className="text-center w-full mx-auto text-xl md:text-2xl font-bold">
            {modalText}
          </div>
        </div>
      </Modal>

      <button onClick={handleSubmit} className="relative bg-yellow-500 border-b-2 border-yellow-600 overflow-hidden flex items-center justify-around p-4 w-full lg:w-72 mx-auto text-base text-center text-white block font-bold rounded-full transition duration-300" disabled={buttonDisabled}>
        <span className="block z-10 underline">登録</span>
      </button>
    </div>
  )
}

export default FormButton
