import React, { useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import jaLocale from '@fullcalendar/core/locales/ja'
import interactionPlugin from '@fullcalendar/interaction'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import SimpleReserveEvents from './SimpleReserveEvents'

dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const Calendar = ({ events, handleEventClick }) => {
  const [activeDate, setActiveDate] = useState(null);
  const [selectDayEvents, setSelectDayEvents] = useState([])
  const calendarEvents = events.map((event, index) => {
    return {
      id: event.id,
      title: '予約空枠',
      start: event.start_at,
      end: event.end_at,
      counselorId: event.counselor_id,
      advisorId: event.advisor_id
    }
  })
  const viewEvents = events.map((event, index) => {
    return {
      id: event.id,
      start: dayjs(event.start_at).tz().format('YYYY-MM-DD'),
      end: dayjs(event.end_at).tz().format('YYYY-MM-DD'),
      display: 'background',
      counselorId: event.counselor_id,
      advisorId: event.advisor_id
    }
  })

  const now = dayjs().add(1, "day").tz().format('YYYY-MM-DD')
  const three_month_later = dayjs().tz().add(3, "month").endOf('month').format('YYYY-MM-DD')

  const handleDateClick = (info) => {
    // タイムゾーンの影響を考慮して日付を処理
    const clickedDate = new Date(info.date)
    clickedDate.setMinutes(clickedDate.getMinutes() + clickedDate.getTimezoneOffset())
    const dateStr = clickedDate.toISOString().split('T')[0]
    setActiveDate(dateStr)

    const events = getDateEvent(info.date)
    setSelectDayEvents(events)

    // scroll to reserves
    const reserves = document.getElementById('reserves')
    const reservesTop = reserves.getBoundingClientRect().top
    const scrollY = window.pageYOffset
    const top = reservesTop + scrollY
    window.scrollTo({
      top: top,
      behavior: 'smooth'
    })
  }

  const getDateEvent = (day) => {
    const startDate = dayjs(day).hour(0).minute(0).format()
    const endDate = dayjs(day).hour(23).minute(59).format()

    return calendarEvents.map((event, index) => {
      const eventStartDate = dayjs(event.start).hour(0).minute(0).format()
      const eventEndDate = dayjs(event.end).hour(23).minute(59).format()
      // 日付の一致を確認
      if (startDate === eventStartDate && endDate === eventEndDate) {
        return event
      }
    }).filter(e => e)
  }

  const hasEvents = (date) => {
    const events = getDateEvent(date)
    return events && (events.length > 0)
  }

  const eventCounts = (date) => {
    const events = getDateEvent(date)
    if (!events) return 0
    return events.length
  }

  return (
    <div className="md:flex w-full simple-calendar">
      <div className="w-full md:w-1/2 mx-2">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
          contentHeight='auto'
          initialView="dayGridMonth"
          slotMinTime='09:00:00'
          slotMaxTime='22:00:00'
          slotDuration='00:15:00'
          slotLabelInterval='00:30'
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short'
          }}
          locales={[jaLocale]}
          locale='ja'
          //eventClick={handleDateClick}
          dateClick={handleDateClick}
          validRange={{
            start: now,
            end: three_month_later
          }}
          headerToolbar={{
            left: 'title',
            right: 'prev,next',
          }}
          showNonCurrentDates={false}
          events={viewEvents}
          views={{
            dayGridMonth: {
              dayCellClassNames: function (e) {
                const dateStr = e.date.toISOString().split('T')[0]
                return dateStr === activeDate ? ['fc-day-active'] : [];
              },
              dayCellContent: function (e, createElement) {
                e.dayNumberText = e.dayNumberText.replace('日', '');

                let color = '#000'
                const eventLength = eventCounts(e.date)
                if (eventLength <= 0) {
                  color = '#ccc'
                }

                const dateElement = createElement(
                  'span',
                  {
                    style: {
                      color: color
                    }
                  },
                  e.dayNumberText,
                )

                return [dateElement]
              }
            }
          }}
        />
      </div>
      <div id="reserves" className="w-full md:w-1/2 overflow-hidden mt-8 md:mt-0">
        <SimpleReserveEvents events={selectDayEvents} handleEventClick={handleEventClick} activeDate={activeDate} />
      </div>
    </div>
  )
}

export default Calendar
