import React, {useState,useEffect} from 'react'

const CounselingCouponForm = ({ hasError }) => {
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const couponCode = params.get('discount_coupon_code')
    if (couponCode) {
      document.getElementById('discount_coupon_code').value = couponCode

      if (!hasError) {
        const counselingDetail = document.getElementById('counseling_detail')
        const counselingDetailTop = counselingDetail.getBoundingClientRect().top
        const scrollY = window.pageYOffset
        const top = counselingDetailTop + scrollY
        window.scrollTo({
          top: top,
          behavior: 'smooth'
        })
      }
    }
  }, [])

  const handleCouponSubmit = async (event) => {
    event.preventDefault()

    const couponCode = document.getElementById('discount_coupon_code').value
    if (!isValid(couponCode)) return

    const params = new URLSearchParams(location.search)
    params.set('discount_coupon_code', couponCode)
    const url = `${location.pathname}?${params.toString()}`
    location.href = url
  }

  const isValid = (couponCode) => {
    setErrorMessage(null)

    if(!couponCode) {
      setErrorMessage('割引クーポンが入力されていません。')
      return false
    }

    return true
  }

  return (
    <>
      <div className="w-full mt-4 md:mt-0 flex items-center">
        <input type="text" name="discount_coupon_code" id="discount_coupon_code" className="border-gray-300 border appearance-none block w-full bg-white rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" />
        <button onClick={handleCouponSubmit} type="button" className="bg-red-500 w-32 ml-2 hover:bg-red-600 text-white font-bold duration-200 py-2 px-4 rounded focus:outline-none focus:shadow-outline" id="discount_coupon_code_button">
          適用
        </button>
      </div>
      {errorMessage && <div className="font-bold mt-6 text-red-500">{errorMessage}</div>}
    </>
  )
}

export default CounselingCouponForm
