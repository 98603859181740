import React, { useState, useRef, useEffect } from 'react'
import ChatCalendar from './ChatCalendar'
import axios from 'axios'
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const csrfToken = document.querySelector('[name=csrf-token]').content

// browser back event
const reserveActionLabels = [
  'トップ',
  'トップ',
  'AI回答',
  '日付選択',
  'カウンセラー選択',
  '予約完了'
]
history.pushState(null, null, null);
window.addEventListener('popstate', function(event) {
  const currentMessageNumber = document.getElementById('current_message_number').value
  const label = reserveActionLabels[currentMessageNumber] ? reserveActionLabels[currentMessageNumber] : '-'

  if (typeof window.gtag === 'function') {
    window.gtag('event', 'reserve_chat_browser_back', {
      'event_category': 'カウンセリングチャット予約',
      'event_label': label,
      transport_type: 'beacon'
    });
  }

  history.back();
});

const ConversationReserve = ({ reserves, isAffiliationCompany, lastTimeCounselor, lastTimeCounselingMonth }) => {
  const maxCurrentMessage = 4
  const [emptyReserves, setEmptyReserves] = useState(reserves)
  const [currentMessage, setCurrentMessage] = useState(lastTimeCounselor ? 0 : 1)
  const [sendMessageText, setSendMessageText] = useState(null)
  const [responseMessage, setResponseMessage] = useState(null)
  const [showLoading, setShowLoading] = useState(false)
  const [selectedEventStartAt, setSelectedEventStartAt] = useState(null)
  const [selectedEventJapaneseStartAt, setSelectedEventJapaneseStartAt] = useState(null)
  const [counselors, setCounselors] = useState([])
  const [reserveCounselorName, setReserveCounselorName] = useState(null)
  const [reserveCounselorId, setReserveCounselorId] = useState(null)
  const [reserveId, setReserveId] = useState(null)
  const [reserveZoomUrl, setReserveZoomUrl] = useState(null)
  const [reserveStartAt, setReserveStartAt] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [repeatCounselor, setRepeatCounselor] = useState(null)
  const errorRef = useRef(null)

  const sendMessage = () => {
    if (sendMessageText === null || sendMessageText === '') {
      setCurrentMessage(currentMessage + 2)
      return
    }

    fetchResponseMessage()
  }

  useEffect(() => {
    if (currentMessage === 0) {
      postReserveGA4Event('2回目以降_トップ')
    } else if (currentMessage === 1) {
      postReserveGA4Event('新規_トップ')
    } else if (currentMessage === 2) {
      if (repeatCounselor) {
        postReserveGA4Event('前回カウンセラー_AI回答')
      } else {
        postReserveGA4Event('AI回答')
      }
    } else if (currentMessage === 3) {
      if (repeatCounselor) {
        postReserveGA4Event('前回カウンセラー_日付選択')
      } else {
        postReserveGA4Event('日付選択')
      }
    } else if (currentMessage === 4) {
      if (repeatCounselor && isAffiliationCompany) {
        postReserveGA4Event('前回カウンセラー_団体所属_カウンセラー選択')
      } else if (!repeatCounselor && isAffiliationCompany) {
        postReserveGA4Event('団体所属_カウンセラー選択')
      } else if (repeatCounselor && !isAffiliationCompany) {
        postReserveGA4Event('前回カウンセラー_団体未所属_カウンセラー選択')
      } else {
        postReserveGA4Event('団体未所属_カウンセラー選択')
      }
    } else if (currentMessage === 5) {
      if (repeatCounselor) {
        postReserveGA4Event('前回カウンセラー_予約完了')
      } else {
        postReserveGA4Event('予約完了')
      }
    }
  }, [currentMessage])

  const postReserveGA4Event = (eventLabel) => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'reserve_chat_action', {
        'event_category': 'カウンセリングチャット予約',
        'event_label': eventLabel
      });
    }
  }

  const postSaveMessage = (sendMessage, responseText) => {
    axios.post('/users/my_page/api/counseling_chats', {
      message: sendMessage,
      reply_message: responseText
    }, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => {
        console.log(response)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const handleEventClick = (event) => {
    window.scrollTo(0, 0)
    setShowLoading(true)

    const startAt = dayjs(event.start).tz().format('YYYY-MM-DD HH:mm')
    setSelectedEventStartAt(startAt)
    setSelectedEventJapaneseStartAt(dayjs(event.start).tz().format('YYYY年MM月DD日HH:mm'))

    axios.get(`/users/my_page/api/counselors?empty_reserve_start_at=${startAt}&counselor_id=${repeatCounselor ? repeatCounselor.id : ''}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => {
        setCounselors(response.data.counselors)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setShowLoading(false)
        setCurrentMessage(currentMessage + 1)
      })
  }

  const handleRepeatCounselor = () => {
    setShowLoading(true)

    axios.get(`/users/my_page/api/empty_reserves?counselor_id=${lastTimeCounselor.id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => {
        setRepeatCounselor(lastTimeCounselor)
        setEmptyReserves(response.data.empty_reserves)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setShowLoading(false)
        setCurrentMessage(currentMessage + 3)
      })
  }

  const showErrorMessage = () => {
    errorRef.current.classList.add('opacity-100');
    setTimeout(() => {
      errorRef.current.classList.remove('opacity-100');
    }, 2500);
  }

  const handleReserveCounseling = (counselor) => {
    setShowLoading(true)

    // 団体未所属の場合はカウンセリング予約画面へ遷移
    if (!isAffiliationCompany) {
      const userComment = sendMessageText ? sendMessageText : ''
      window.location.href = `/users/my_page/counselors/${counselor.id}/counseling_reserves/${counselor.counseling_reserve_id}?user_comment=${userComment}`
      return
    }

    axios.post('/users/my_page/api/counseling_reserves', {
      counselor_id: counselor.id,
      empty_reserve_start_at: selectedEventStartAt,
      user_comment: sendMessageText
    }, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => {
        setReserveCounselorName(response.data.counselor_name)
        setReserveZoomUrl(response.data.zoom_url)
        setReserveStartAt(dayjs(response.data.start_at).tz().format('YYYY年MM月DD日HH:mm'))
        setShowLoading(false)
        setCurrentMessage(currentMessage + 1)
      })
      .catch(error => {
        showErrorMessage()
        console.log(error)
        setErrorMessage(error.response.data.message)
        console.log(error)
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const fetchResponseMessage = () => {
    setShowLoading(true)

    axios.post('/users/my_page/api/counseling_bots', {
      message: sendMessageText
    }, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      }
    })
      .then(response => {
        setResponseMessage(response.data.message)

        postSaveMessage(sendMessageText, response.data.message)
      })
      .catch(error => {
        console.log(error)
        setShowLoading(false)
      })
      .finally(() => {
        setShowLoading(false)
        setCurrentMessage(currentMessage + 1)
      })
  }

  const nextMessage = () => {
    setCurrentMessage(currentMessage + 1)
  }

  const currentMessageComponent = (currentNumber, reserves) => {
    switch (currentNumber) {
      case 0:
        return (
          <div>
            <div className="flex justify-between">
              <div className="w-1/5 md:w-1/4 flex justify-center items-center">
                <div className="rounded-full w-32 h-32">
                  <img src="/images/mscG_01.png" alt="icon" />
                </div>
              </div>
              <div className="w-3/4 h-full relative">
                <div className="shadow-md text-sm md:text-base rounded-lg bg-white p-4 h-full message-1 opacity-0 speech-bubble-left">
                  こんにちは。<br />
                  {lastTimeCounselingMonth !== null && lastTimeCounselingMonth !== undefined && lastTimeCounselingMonth > 0 && (
                    <>
                      前回のカウンセリングから{lastTimeCounselingMonth}ヶ月経過しました。<br />
                    </>
                  )}
                  前回と同じカウンセラーでご予約されますか？
                </div>
              </div>
            </div>

            <div className="flex-wrap md:flex-nowrap flex justify-end mt-6">
              <div className="w-full md:w-auto flex justify-end">
                <button onClick={handleRepeatCounselor} className="rounded-full bg-green-400 text-white px-12 py-2 text-white flex items-center justify-center text-center md:mr-4 button-allow-right text-base font-bold">
                  はい
                </button>
              </div>
              <div className="w-full md:w-auto flex justify-end">
                <button onClick={() => setCurrentMessage(currentMessage + 1)} className="mt-2 md:mt-0 rounded-full bg-yellow-400 text-white px-12 py-2 text-white flex items-center justify-center text-center button-allow-right text-base font-bold">
                  他のカウンセラーで予約する
                </button>
              </div>
            </div>
          </div>
        )
      case 1:
        return (
          <div>
            <div className="flex justify-between">
              <div className="w-1/5 md:w-1/4 flex justify-center items-center">
                <div className="rounded-full w-32 h-32">
                  <img src="/images/mscG_01.png" alt="icon" />
                </div>
              </div>
              <div className="w-3/4 h-full">
                <div className="relative">
                  <div className="shadow-md text-sm md:text-base rounded-lg bg-white p-4 h-full message-1 opacity-0 speech-bubble-left">
                    {!lastTimeCounselor && (
                      <>
                        こんにちは。<br />
                      </>
                    )}
                    本日はどうされましたか？<br />
                    <span className="text-gray-500">(入力せずにスキップできます。「次へ」ボタンを選択してください)</span>
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="相談内容を入力してください"
                  className="w-full border-2 border-gray-300 rounded-lg p-2 mt-4 message-2 opacity-0"
                  value={sendMessageText}
                  onChange={(e) => setSendMessageText(e.target.value)}
                  maxLength="500"
                />
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button onClick={sendMessage} className="rounded-full bg-yellow-400 text-white px-12 py-2 text-white flex items-center justify-center text-center button-allow-right text-base font-bold">
                次へ
              </button>
            </div>
          </div>
        )
      case 2:
        return (
          <div>
            <div className="flex justify-between">
              <div className="w-1/5 md:w-1/4 flex justify-center items-center">
                <div className="rounded-full w-32 h-32">
                  <img src="/images/mscG_01.png" alt="icon" />
                </div>
              </div>
              <div className="w-3/4 relative">
                <div className="shadow-md text-sm md:text-base rounded-lg bg-white h-full p-4 message-1 opacity-0 speech-bubble-left">
                  こんにちは。本日はどうされましたか？<br />
                  <span className="text-gray-500">(入力せずにスキップできます。「次へ」ボタンを選択してください)</span>
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              <div className="text-sm md:text-base flex w-3/4 shadow-md rounded-lg bg-gray-100 p-4 mt-6 message-2 opacity-0 speech-bubble-left">
                {sendMessageText}
              </div>
            </div>

            <div className="flex justify-between mt-6">
              <div className="w-1/5 md:w-1/4 flex justify-center items-center">
                <div className="rounded-full w-32 h-32">
                  <img src="/images/mscG_01.png" alt="icon" />
                </div>
              </div>

              <div className="w-3/4 relative">
                <div className="text-sm md:text-base flex shadow-md h-full rounded-lg bg-white p-4 message-3 opacity-0 speech-bubble-left">
                  {responseMessage && (
                    responseMessage === 'そうですね。' ? (
                      <>
                        すみません、カウンセリングに関係のないメッセージのように思います。<br />
                        おそらく、お悩みのことがあるはずなので、カウンセリング予約をご案内しますね。
                      </>
                    ) : (
                      <>
                        教えていただいてありがとうございます。<br />
                        {responseMessage}<br />
                        そのお気持ちをカウンセラーにお話しください。
                      </>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button onClick={nextMessage} className="rounded-full bg-yellow-400 text-white px-12 py-2 text-white flex items-center justify-center text-center button-allow-right text-base font-bold">
                次へ
              </button>
            </div>

          </div>
        )
      case 3:
        return (
          <div>
            <div className="flex justify-between">
              <div className="w-1/5 md:w-1/4 flex justify-center items-center">
                <div className="rounded-full w-32 h-32">
                  <img src="/images/mscG_01.png" alt="icon" />
                </div>
              </div>
              <div className="w-3/4 relative">
                <div className="shadow-md text-sm md:text-base rounded-lg bg-white p-4 h-full message-1 opacity-0 speech-bubble-left">
                  {repeatCounselor && (
                    <>
                      {repeatCounselor.name}カウンセラーで予約いたします。<br />
                    </>
                  )}
                  ご都合の良い日時を選んでください。
                </div>
              </div>
            </div>

            <div className="mt-10 message-2 opacity-0 shadow-md p-6 rounded-lg">
              <ChatCalendar
                reserves={emptyReserves}
                handleEventClick={handleEventClick}
              />
            </div>
          </div>
        )
      case 4:
        return (
          <div>
            <div className="flex justify-between">
              <div className="w-1/5 md:w-1/4 flex justify-center md:items-center">
                <div className="rounded-full w-32 h-32">
                  <img src="/images/mscG_01.png" alt="icon" />
                </div>
              </div>
              <div className="w-3/4 h-full relative">
                <div className="shadow-md text-sm md:text-base rounded-lg bg-white p-4 message-1 opacity-0">
                  {selectedEventJapaneseStartAt}ですね。
                  {!repeatCounselor && (
                    <>
                      <br />
                      どのカウンセラーでも、きちんと皆さんのサポートをさせていただきますので、安心してくださいね。
                    </>
                  )}
                </div>

                <div className="shadow-md text-sm md:text-base rounded-lg bg-white p-4 mt-4 message-2 opacity-0">
                  {!isAffiliationCompany ? (
                    <>
                      予約に進むカウンセラーを選択してください。
                    </>
                  ) : (
                    <>
                      {repeatCounselor === null && (
                        <>
                          カウンセラーをお選びいただき、「予約完了」ボタンを選択してください。<br />
                        </>
                      )}
                      「予約完了」ボタンを押下すると、予約が確定となります。
                    </>
                  )}
                </div>

                <div className="shadow-md text-sm md:text-base rounded-lg bg-white p-4 mt-4 h-full message-3 opacity-0 speech-bubble-left">

                  {counselors.map((counselor, index) => {
                    return (
                      <div key={index} className="md:flex justify-between items-center p-0 md:p-4 mb-8 md:mb-0">
                        <div className="flex items-center justify-center">
                          {counselor.profile_image_url ? (
                            <div className="bg-gray-300 md:mr-4 w-24 h-24 rounded-full overflow-hidden">
                              <img src={counselor.profile_image_url} alt="icon" className="w-24 h-24 mx-auto block" />
                            </div>
                          ) : (
                            <div className="bg-gray-300 mr-4 w-24 h-24 rounded-full overflow-hidden text-center flex items-center justify-center">
                              No Image
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="font-bold mt-2 md:mt-0">
                            {counselor.name}
                          </div>
                          <div className="bg-gray-100 p-4 md:p-6 rounded-lg mt-2 text-sm leading-7">
                            {counselor.profile_text}
                          </div>
                          <div className="flex justify-end mt-4">
                            <button className="rounded-full bg-yellow-400 text-white px-12 py-2 flex items-center justify-center text-center button-allow-right text-base font-bold" onClick={() => handleReserveCounseling(counselor)}>
                              {isAffiliationCompany ? "予約完了" : "予約に進む"}
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div>
            <div className="flex justify-between">
              <div className="w-1/5 md:w-1/4 flex justify-center items-center">
                <div className="rounded-full w-32 h-32">
                  <img src="/images/mscG_07.png" alt="icon" />
                </div>
              </div>
              <div className="w-3/4 relative">
                <div className="shadow-md text-sm md:text-base rounded-lg bg-white p-4 h-full message-1 opacity-0 speech-bubble-left">
                  ご予約いただきありがとうございます。<br />
                  下記の日程にて確定いたしました。<br />
                  下記または別途送信されるメールのURLへ当日アクセスください。<br />
                  <br />
                  【カウンセリング日時】<br />
                  {reserveStartAt}<br />
                  <br />
                  【Zoom URL】<br />
                  {reserveZoomUrl}<br />
                  <br />
                  【カウンセラー】<br />
                  {reserveCounselorName}<br />
                </div>
              </div>
            </div>
          </div>
        )
    }
  }

  return (
    <>
      <div>
        <div>
          <div className="flex justify-center w-full">
            <div className="fixed top-2 px-12 bg-red-400 text-white rounded-lg py-2 z-50 duration-700 opacity-0 text-sm md:text-base" ref={errorRef}>
              エラー：{errorMessage || 'エラーが発生しました'}
            </div>
          </div>

          {showLoading && (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 z-50 flex justify-center items-center">
              <div className="text-center animate-spin text-white text-7xl">
                <AiOutlineLoading3Quarters />
              </div>
            </div>
          )}
          {currentMessageComponent(currentMessage, reserves)}
          <input type="hidden" id="current_message_number" value={currentMessage} />
        </div>
      </div>
    </>
  )
}

export default ConversationReserve
