import React, { useState } from 'react'
import Calendar from './Calendar'
import Modal from 'react-modal'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.85)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9998
  },
  content: {
    position: "absolute",
    width: "90%",
    height: "auto",
    inset: "initial",
    borderRadius: "1rem",
    padding: "1.5rem",
    zIndex: 9999
  }
}

const NextReserveCalendar = ({ reserves, userId, counselorId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectEvent, setSelectEvent] = useState({})
  const nextReservePath = `/counselors/my_page/next_reserves/${userId}/`
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content

  const handleEventClick = (event) => {
    setModalIsOpen(true)
    setSelectEvent(event)
  }

  const handleReserveClick = () => {
    const form = document.getElementById('next_reserve')
    form.submit()
  }

  return (
    <>
      <Modal
        style={modalStyle}
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <div className="h-full mt-6 p-2 md:p-8">
          <div className="font-bold text-center text-2xl flex justify-center border-b-2 pb-6">
            <h2>この日付で次回予約しますか？</h2>
          </div>

          <div className="mt-6">
            <div className="text-center text-lg font-bold">
              {dayjs(selectEvent.start).format('YYYY年MM月DD日')}
            </div>
            <div className="text-center text-2xl font-bold mt-4">{dayjs(selectEvent.start).tz().format('HH:mm')} ~ {dayjs(selectEvent.end).tz().format('HH:mm')}</div>
          </div>
          <form action={nextReservePath} id="next_reserve" method="post">
            <input type="hidden" name="next_reserve[event_id]" value={selectEvent?.id || ''} />
            <input type="hidden" name="next_reserve[counselor_id]" value={counselorId} />
            <input type="hidden" name="next_reserve[user_id]" value={userId} />
            <input type="hidden" name="authenticity_token" value={csrfToken} />
          </form>
          <div className="w-full mt-6">
            <button onClick={handleReserveClick} className="bg-yellow-500 text-white block w-full mx-auto lg:w-1/3 flex items-center justify-center p-4 border-b-2 border-yellow-600 rounded-xl text-center text-lg font-bold">
              <span className="">次回予約</span>
            </button>
          </div>

          <button className="block mx-auto underline mt-10 font-bold text-center" onClick={() => setModalIsOpen(false)}>✕ 閉じる</button>
        </div>
      </Modal>

      <Calendar
        events={reserves}
        handleEventClick={handleEventClick}
      />
    </>
  )
}

export default NextReserveCalendar
