import React from 'react'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import { Radar } from 'react-chartjs-2'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
)

const StressStateChart = ({
  state1Point, state2Point, state3Point, state4Point, state5Point, state6Point,
  lastState1Point, lastState2Point, lastState3Point, lastState4Point, lastState5Point, lastState6Point,
}) => {
  const options = {
    plugins: {
      tooltip: {
      },
      legend: {
        title: {
          color: '#000',  // 凡例のタイトルの色
          font: {
            size: 10,  // 凡例のタイトルの文字の大きさ
          },
        },
        labels: {
          color: '#000',  // 凡例のラベルの色
          font: {
            size: 10,  // 凡例のタイトルの文字の大きさ
          },
        },
      },
    },
    scales: {
      r: {
        title: {
          color: '#000',  // スケールのタイトルの色
        },
        grid: {
          borderColor: '#525252',  // スケールのグリッドの色
          color: '#525252',        // スケールのグリッドの色
          tickColor: '#525252',    // スケールのグリッドの色
        },
        angleLines: {
          color: '#525252',  // 角度の線の色
        },
        pointLabels: {
          color: '#000',  // ポイントラベルの色
          font: {
            size: 10,  // ポイントラベルの文字の大きさ
          },
        },
        min: 0,
        max: 5,
        ticks: {
          color: '#000',  // スケールの目盛りの色
          precision: 0,   // 小数点以下の桁数を0に設定
          font: {
            size: 10,  // スケールの目盛りの文字の大きさ
          },
        },
        textStrokeColor: '#525252',  // スケールのテキストの輪郭の色
      },
    },
  }

  const data = {
    labels: [
      '活気',
      'イライラ　　',
      '疲労感',
      '不安感',
      'うつ気分',
      '　　体の不調',
    ],
    datasets: [
      {
        label: 'ストレスによって起こる心身の反応',
        data: [
          state1Point, state2Point, state3Point, state4Point, state5Point, state6Point
        ],
        animation: false,
        backgroundColor: '#60a5fa94',
        borderColor: '#60a5fa',
        borderWidth: 1,
      },
    ],
  }

  if (lastState1Point) {
    data.datasets.push({
      label: '前回',
      data: [
        lastState1Point, lastState2Point, lastState3Point, lastState4Point, lastState5Point, lastState6Point
      ],
      animation: false,
      backgroundColor: '#94949480',
      borderColor: '#52525280',
      borderWidth: 1,
    })
  }

  return (
    <Radar data={data} options={options} />
  )
}

export default StressStateChart
