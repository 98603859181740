import React from 'react'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import { Radar } from 'react-chartjs-2'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
)

const StressWorkChart = ({ 
  work1Point,
  work2Point,
  work3Point,
  work4Point,
  work5Point,
  work6Point,
  work7Point,
  work8Point,
  work9Point,
  lastWork1Point,
  lastWork2Point,
  lastWork3Point,
  lastWork4Point,
  lastWork5Point,
  lastWork6Point,
  lastWork7Point,
  lastWork8Point,
  lastWork9Point,
}) => {
  const options = {
    plugins: {
      tooltip: {
        //font: {
        //  size: 16,  // ツールチップの文字の大きさ
        //},
        //titleFont: {
        //  size: 16,  // ツールチップのタイトルの文字の大きさ
        //},
      },
      legend: {
        title: {
          color: '#000',  // 凡例のタイトルの色
          font: {
            size: 10,  // 凡例のタイトルの文字の大きさ
          },
          padding: 0,
        },
        labels: {
          color: '#000',  // 凡例のラベルの色
          font: {
            size: 10,  // 凡例のタイトルの文字の大きさ
          },
        },
      },
    },
    scales: {
      r: {
        title: {
          color: '#000',  // スケールのタイトルの色
        },
        grid: {
          borderColor: '#525252',  // スケールのグリッドの色
          color: '#525252',        // スケールのグリッドの色
          tickColor: '#525252',    // スケールのグリッドの色
        },
        angleLines: {
          color: '#525252',  // 角度の線の色
        },
        pointLabels: {
          color: '#000',  // ポイントラベルの色
          callback: function (label, index) {
            if (label.length >= 4) {
              return label.split(/\n/)
            }
            return label
          },
          font: {
            size: 10,  // ポイントラベルの文字の大きさ
          },
        },
        min: 0,
        max: 5,
        ticks: {
          color: '#000',  // スケールの目盛りの色
          precision: 0,   // 小数点以下の桁数を0に設定
          font: {
            size: 10,  // スケールの目盛りの文字の大きさ
          },
        },
        textStrokeColor: '#525252',  // スケールのテキストの輪郭の色
      },
    },
    layout: {
      padding: {
        right: 0,
        top: 0,
        bottom: 0
      }
    }
  }

  const data = {
    labels: [
      '量的負担',
      '質的負担',
      '身体負担',
      '対人関係',
      '職場環境',
      'コントロール',
      '技能活用',
      '適正度',
      '働きがい'
    ],
    datasets: [
      {
        label: 'ストレスの原因と考えられる要因',
        data: [
          work1Point,
          work2Point,
          work3Point,
          work4Point,
          work5Point,
          work6Point,
          work7Point,
          work8Point,
          work9Point,
        ],
        animation: false,
        backgroundColor: ' #f8717194',
        borderColor: '#f87171',
        borderWidth: 1,
      },
    ],
  }

  console.log(lastWork1Point)

  if (lastWork1Point) {
    data.datasets.push({
      label: '前回',
      data: [
        lastWork1Point,
        lastWork2Point,
        lastWork3Point,
        lastWork4Point,
        lastWork5Point,
        lastWork6Point,
        lastWork7Point,
        lastWork8Point,
        lastWork9Point,
      ],
      animation: false,
      backgroundColor: '#94949480',
      borderColor: '#52525280',
      borderWidth: 1,
    })
  }

  return (
    <Radar data={data} options={options} />
  )
}
 
export default StressWorkChart
