import React, { useState } from 'react'
import Calendar from './Calendar'
import Modal from 'react-modal'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const modalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.85)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9998
  },
  content: {
    position: "absolute",
    width: "90%",
    height: "auto",
    inset: "initial",
    borderRadius: "1rem",
    padding: "1.5rem",
    zIndex: 9999
  }
}

const AdvisorReserveUpdateCalendar = ({ reserves, advisorReserveId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectEvent, setSelectEvent] = useState({})
  const updateReservePath = `/users/my_page/advisor_reserves/${advisorReserveId}/`
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submitText, setSubmitText] = useState('予約処理中です...。このまましばらくお待ちください。')

  const handleEventClick = (event) => {
    setModalIsOpen(true)
    setSelectEvent(event)
  }

  const handleUpdateReserveClick = () => {
    setIsSubmitted(true)

    const form = document.getElementById('update_reserve')
    form.submit()
  }

  return (
    <>
      <Modal
        style={modalStyle}
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <div className="h-full">
          {isSubmitted ?
            <div className="flex h-full items-center flex-wrap">
              <div className="text-center w-full mx-auto my-40 text-xl md:text-2xl font-bold">
                {submitText}
              </div>
            </div>
          :
            <>
              <div className="font-bold text-center text-2xl flex justify-center border-b-2 pb-6">
                <h2>日時変更</h2>
              </div>

              <div className="mt-6 font-bold text-center text-red-500">
                <p>この日時に変更しますか?</p>
              </div>

              <div className="mt-6">
                <div className="text-center text-lg font-bold">
                  {dayjs(selectEvent.start).format('YYYY年MM月DD日')}
                </div>
                <div className="text-center text-2xl font-bold mt-4">{dayjs(selectEvent.start).format('HH:mm')} ~ {dayjs(selectEvent.end).format('HH:mm')}</div>
              </div>

              <form action={updateReservePath} id="update_reserve" method="post">
                <input type="hidden" name="_method" value="patch" />
                <input type="hidden" name="update_reserve[destination_reserve_id]" value={selectEvent?.id || ''} />
                <input type="hidden" name="authenticity_token" value={csrfToken} />
              </form>

              <div className="form flex justify-center items-center mt-8">
                <button onClick={handleUpdateReserveClick} className="block font-bold rounded-xl text-center bg-yellow-500 border-b-2 border-yellow-600 text-white p-4">日時を変更する</button>
              </div>

              <button className="block mx-auto underline mt-6 font-bold text-center" onClick={() => setModalIsOpen(false)}>✕ 閉じる</button>
            </>
          }
        </div>
      </Modal>

      <Calendar
        events={reserves}
        handleEventClick={handleEventClick}
      />
    </>
  )
}

export default AdvisorReserveUpdateCalendar
