import React, {useState,useEffect,useRef} from 'react'
import axios from 'axios'
import Modal from 'react-modal'
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const AgreementPopup = ({ privacyPolicy, termsOfService, privacyPolicyId, termsOfServiceId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [agreementPopupElement, setAgreementPopupElement] = useState(null)
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content

  let agreementCategories = []
  if (termsOfService) {
    agreementCategories.push('利用規約')
  }
  if (privacyPolicy) {
    agreementCategories.push('プライバシーポリシー')
  }

  useEffect(() => {
    if (termsOfService || privacyPolicy) {
      setModalIsOpen(true)
    }
  }, [])

  const handleScroll = (e) => {
    // 一番下ではなく、スクロールがある程度進んだらボタンを有効化
    const scrollHeight = e.target.scrollHeight
    const scrollTop = e.target.scrollTop + 300
    const clientHeight = e.target.clientHeight
    if ((scrollHeight - scrollTop) < clientHeight) {
      setButtonDisabled(false)
    }
  }

  useEffect(() => {
    // scroll幅が無い場合はボタンを有効化
   if (agreementPopupElement) {
      if (agreementPopupElement.scrollHeight <= agreementPopupElement.clientHeight) {
        setButtonDisabled(false)
      }
    }
  }, [agreementPopupElement])

  const handleAgree = () => {
    setLoading(true)

    const privacyPolicyAgreed = privacyPolicy ? true : false
    const termsOfServiceAgreed = termsOfService ? true : false
    // api call
    axios.post('/users/my_page/api/user_agreements', {
      user_agreement: {
        privacy_policy_id: privacyPolicyId,
        terms_of_service_id: termsOfServiceId,
      },
      authenticity_token: csrfToken,
    })
    .then((response) => {
      // success
      setModalIsOpen(false)
    })
    .catch((error) => {
      // error
      setLoading(false)
      setError(true)
      console.error(error)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  return (
    <Modal
      style={{
        overlay: {
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#000000a8',
          alignItems: 'center',
          zIndex: '100'
        },
        content: {
          position: 'absolute',
          width: '90%',
          height: '90%',
          inset: 'initial',
          borderRadius: '1rem',
          backgroundColor: '#f8f8f8',
          padding: '1.5rem',
          zIndex: '101'
        }
      }}
      isOpen={modalIsOpen}
      ariaHideApp={false}
    >
      {error && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-xl font-bold p-4">
            エラーが発生しました。時間をおいて再度お試しください。
          </div>
        </div>
      )}

      {loading && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center animate-spin text-gray-900 text-7xl">
            <AiOutlineLoading3Quarters />
          </div>
        </div>
      )}

      {!loading && !error && (
        <div className="text-center h-full flex flex-col justify-between">
          <p className="text-base">
            {agreementCategories.join('、')}の更新に同意いただける場合は、下記の内容をご確認の上、同意ボタンを押してください。<br />
            ※スクロールが最下部まで進むと同意ボタンが有効化されます。
          </p>
          <div className="text-left border border-gray-300 p-4 h-4/5 overflow-y-scroll bg-white scroll-text" onScroll={handleScroll} ref={setAgreementPopupElement}>
            {termsOfService && (
              <>
                <h2 className="text-2xl font-bold mb-4 pb-2 text-center border-b">利用規約</h2>
                <p className="whitespace-pre-wrap mb-4">
                  {termsOfService}
                </p>
              </>
            )}
            {privacyPolicy && (
              <>
                <h2 className="text-2xl font-bold mb-4 pb-2 text-center border-b">プライバシーポリシー</h2>
                <p className="whitespace-pre-wrap">
                  {privacyPolicy}
                </p>
              </>
            )}
          </div>
          <button onClick={() => handleAgree()} className={`mt-4 text-white font-bold py-2 px-4 rounded-lg ${buttonDisabled ? 'bg-gray-300' : 'bg-yellow-500'}`} disabled={buttonDisabled}>同意する</button>
        </div>
      )}
    </Modal>
  )
}

export default AgreementPopup
