import React from 'react'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'
import { Radar } from 'react-chartjs-2'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
)

const StressAroundChart = ({
  around1Point,
  around2Point,
  around3Point,
  satisfaction1Point,
  lastAround1Point,
  lastAround2Point,
  lastAround3Point,
  lastSatisfaction1Point
}) => {
  const options = {
    plugins: {
      tooltip: {
      },
      legend: {
        title: {
          color: '#000',  // 凡例のタイトルの色
          font: {
            size: 10,  // 凡例のタイトルの文字の大きさ
          },
        },
        labels: {
          color: '#000',  // 凡例のラベルの色
          font: {
            size: 10,  // 凡例のタイトルの文字の大きさ
          },
        },
      },
    },
    scales: {
      r: {
        title: {
          color: '#000',  // スケールのタイトルの色
        },
        grid: {
          borderColor: '#525252',  // スケールのグリッドの色
          color: '#525252',        // スケールのグリッドの色
          tickColor: '#525252',    // スケールのグリッドの色
        },
        angleLines: {
          color: '#525252',  // 角度の線の色
        },
        pointLabels: {
          color: '#000',  // ポイントラベルの色
          font: {
            size: 10,  // ポイントラベルの文字の大きさ
          },
          callback: function (label, index) {
            if (label.length >= 4) {
              return label.split(/\n/)
            }
            return label
          },
        },
        min: 0,
        max: 5,
        ticks: {
          color: '#000',  // スケールの目盛りの色
          precision: 0,   // 小数点以下の桁数を0に設定
          font: {
            size: 10,  // スケールの目盛りの文字の大きさ
          },
        },
        textStrokeColor: '#525252',  // スケールのテキストの輪郭の色
      },
    },
  }

  const data = {
    labels: [
      '上司支援',
      '同僚支援',
      '家族・友人支援',
      '満足度',
    ],
    datasets: [
      {
        label: 'ストレス反応に影響を与える他の要因',
        data: [
          around1Point,
          around2Point,
          around3Point,
          satisfaction1Point,
        ],
        animation: false,
        backgroundColor: '#34d39994',
        borderColor: '#34d399',
        borderWidth: 2,
      },
    ],
  }

  if (lastAround1Point) {
    data.datasets.push({
      label: '前回',
      data: [
        lastAround1Point,
        lastAround2Point,
        lastAround3Point,
        lastSatisfaction1Point,
      ],
      animation: false,
      backgroundColor: '#94949480',
      borderColor: '#52525280',
      borderWidth: 1,
    })
  }


  return (
    <Radar data={data} options={options} />
  )
}

export default StressAroundChart
