import React, {useState,useEffect} from 'react'
import Modal from 'react-modal'
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js'

const UpdateForm = () => {
  const stripe = useStripe()
  const elements = useElements()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalText, setModalText] = useState('登録処理中です...。このまましばらくお待ちください。')
  const [errorMessage, setErrorMessage] = useState(null)
  const [buttonDisabled, setButtonDisabled] = useState(true)

  useEffect(() => {
    setButtonDisabled(!stripe)
  })

  const handleSubmit = async (event) => {
    event.preventDefault()
    setModalIsOpen(true)
    setButtonDisabled(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    //client_secretを利用して（確認情報をStripeに投げて）決済を完了させる
    const {error, token} = await stripe.createToken(elements.getElement(CardElement))

    if(error) {
      setErrorMessage(error.message)
      setButtonDisabled(false)
      setModalIsOpen(false)
    }else if(token){
      const form = document.getElementById('edit_user')
      const hiddenInput = document.createElement('input')
      hiddenInput.setAttribute('type', 'hidden')
      hiddenInput.setAttribute('name', 'card_token')
      hiddenInput.setAttribute('value', token.id)
      form.appendChild(hiddenInput)
      form.submit()
    }else{
      setErrorMessage("カード登録エラー。時間をあけて再度お試しください。");
      setButtonDisabled(false)
      setModalIsOpen(false)
    }
  }

  return (
    <>
      <Modal 
        style={{
          overlay: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '100'
          },
          content: {
            position: 'absolute',
            width: '60%',
            margin: '15% auto',
            padding: '0',
            inset: '0',
            border: '3px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
          }
        }}
        isOpen={modalIsOpen}
      >
        <div className="flex h-full items-center flex-wrap">
          <div className="text-center w-full mx-auto text-xl md:text-2xl font-bold">
            {modalText}
          </div>
        </div>
      </Modal>

      <form onSubmit={handleSubmit}>
        <div className="mt-2 md:flex border-gray-300">
          <div className="w-full md:w-1/3 flex justify-between items-center md:pr-6 font-bold">
            クレジットカード情報
            <div>
              <p className="bg-pink-400 text-white text-xs rounded-lg p-1 px-2">必須</p>
            </div>
          </div>
          <div className="w-full mt-4 md:mt-0 md:w-2/3">
            <div className="border border-gray-300 rounded py-3 px-4">
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontWeight: '500',
                      fontSize: '16px',
                      color: "#32325d",
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>

        {errorMessage && <div className="font-bold mt-6 text-red-500">{errorMessage}</div>}

        { stripe
         ? <div className="mt-14">
            <button className="relative bg-yellow-500 border-b-2 border-yellow-600 overflow-hidden flex items-center justify-around p-4 w-full lg:w-72 mx-auto text-base mt-6 text-center text-white block font-bold rounded-full transition duration-300">
              <span className="block z-10 underline">変更する</span>
            </button>
          </div>
         : <p>エラーが発生しました。時間をおいて再度お試しください。</p>
        }
      </form>
    </>
  )
}

export default UpdateForm
