import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const CompanyStressCheckAroundBarChart = ({
  stressCheckEvent,
  lastStressCheckEvent,
}) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          boxWidth: 0,
          boxHeight: 0,
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        min: 20,
        max: 80,
      }
    },
  }

  const labels = [
    '上司支援',
    '同僚支援',
    '家族・友人支援',
    '満足度',
  ]
 
  const deviationColor = (deviation) => {
    // ・偏差値54以上の項目を青、46以下を赤、それ以外をオレンジ
    if (deviation >= 54) {
      return '#349eed'
    } else if (deviation <= 46) {
      return '#f87171'
    } else {
      return '#f59e0b'
    }
  }
 
  const lastStressCheckEventName = lastStressCheckEvent?.name || ''

  const data = {
    labels,
    datasets: [
      {
        label: stressCheckEvent.name,
        animation: false,
        data: [
          stressCheckEvent.around1PointDeviation,
          stressCheckEvent.around2PointDeviation,
          stressCheckEvent.around3PointDeviation,
          stressCheckEvent.satisfaction1PointDeviation
        ],
        backgroundColor: [
          deviationColor(stressCheckEvent.around1PointDeviation),
          deviationColor(stressCheckEvent.around2PointDeviation),
          deviationColor(stressCheckEvent.around3PointDeviation),
          deviationColor(stressCheckEvent.satisfaction1PointDeviation)
        ],
      },
      {
        label: lastStressCheckEventName,
        animation: false,
        data: [
          lastStressCheckEvent.around1PointDeviation,
          lastStressCheckEvent.around2PointDeviation,
          lastStressCheckEvent.around3PointDeviation,
          lastStressCheckEvent.satisfaction1PointDeviation
        ],
        backgroundColor: '#ddd',
      },
    ],
  }

  return <Bar options={options} data={data} />;
}

export default CompanyStressCheckAroundBarChart
