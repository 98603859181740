import React, { useState, useEffect } from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js'
import { Scatter } from 'react-chartjs-2'

let bg_image = new Image()
bg_image.src = '/images/stress_check_bg01.png'

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)

const CompanyStressCheckJobChart = ({ avgWorkControlScore, avgWorkAmountScore }) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    // 画像の読み込み完了時にimageLoadedをtrueにセット
    bg_image.onload = () => {
      setImageLoaded(true)
    }
  }, [])

  const quadrants = {
    id: 'quadrants',
    beforeDraw(chart, args, options) {
      if (!imageLoaded) return
      const ctx = chart.ctx
      const xPos = 49
      const yPos = 10
      ctx.drawImage(bg_image, xPos, yPos, 291, 290)
    }
  }

  const data = {datasets: []}
  const dataObj = {
    data: [
      {
        x: avgWorkAmountScore, // 仕事の量的負荷
        y: avgWorkControlScore, // 仕事のコントロール
      }
    ],
    backgroundColor: 'rgba(255, 0, 0, 0.5)',
    pointRadius: 6,
  }
  data.datasets.push(dataObj)

  const options = {
    maintainAspectRatio: false,
    responsive: false,
    maintainAspectRatio: false,
    aspectRatio: 50,
    bounds: 'data',
    scales: {
      y: {
        title: {
          display: true,
          text: '仕事のコントロール',
        },
        max: 11,
        min: 4,
        grid: {
          display: false
        }
      },
      x: {
        title: {
          display: true,
          text: '仕事の量的負荷',
        },
        max: 12,
        min: 5,
        grid: {
          display: false
        }
      },
    },
    plugins: {
      quadrants: {
        bottomRight: '#ff686882',
        bottomLeft: '#ff686882',
      },
      legend: {
        display: false,
      },
    }
  }

  const plugins = [quadrants]
    
  return (
    imageLoaded ? (
      <Scatter id={'stressCheckJobChart'} options={options} data={data} plugins={plugins} width={350} height={350} />
    ) : null
  )
}

export default CompanyStressCheckJobChart
