import React, { useState, useEffect } from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js'
import { Scatter } from 'react-chartjs-2'

let bg_image = new Image()
bg_image.src = '/images/stress_check_bg02.png'

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)

const CompanyStressCheckWorkChart = ({ avgBossSupportScore, avgCoworkerSupportScore }) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  useEffect(() => {
    // 画像の読み込み完了時にimageLoadedをtrueにセット
    bg_image.onload = () => {
      setImageLoaded(true)
    }
  }, [])

  const quadrants = {
    id: 'quadrants',
    beforeDraw(chart) {
      if (!imageLoaded) return
      const ctx = chart.ctx
      const xPos = 49
      const yPos = 10
      ctx.drawImage(bg_image, xPos, yPos, 291, 290)
    }
  }

  const data = {
    datasets: [
      {
        data: [{ x: avgBossSupportScore, y: avgCoworkerSupportScore }],
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        pointRadius: 6,
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    responsive: false,
    scales: {
      y: {
        title: { display: true, text: '同僚の支援' },
        max: 11,
        min: 5,
        ticks: { stepSize: 1 },
        grid: { display: false }
      },
      x: {
        title: { display: true, text: '上司の支援' },
        max: 10,
        min: 4,
        ticks: { stepSize: 1 },
        grid: { display: false }
      }
    },
    plugins: {
      quadrants: { bottomRight: '#ff686882', bottomLeft: '#ff686882' },
      legend: { display: false }
    }
  }

  const plugins = [quadrants]

  return (
    imageLoaded ? (
      <Scatter options={options} data={data} plugins={plugins} width={350} height={350} />
    ) : null
  )
}

export default CompanyStressCheckWorkChart
