import React, { useState } from 'react'
import Modal from 'react-modal'

const MatchingForm = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalText, setModalText] = useState('カウンセラーを検索しています...')
  const [errorMessage, setErrorMessage] = useState(null)

  const [questions, setQuestions] = useState([
    {
      title: 'カウンセラーの年齢の希望',
      category: 'age',
      inputType: 'radioButton',
      required: true,
      answers: [
        { label: '30代以下', checked: false },
        { label: '40代以上', checked: false },
        { label: 'こだわらない', checked: false },
      ]
    },
    {
      title: 'カウンセラーの性別の希望',
      category: 'gender',
      inputType: 'radioButton',
      required: true,
      answers: [
        { label: '男性', checked: false },
        { label: '女性', checked: false },
        { label: 'こだわらない', checked: false },
      ]
    },
    {
      title: '利用したい時間帯',
      category: 'time_zone',
      inputType: 'checkbox',
      required: true,
      answers: [
        { label: '平日日中(9時~18時)', checked: false },
        { label: '平日夜間(18時~22時)', checked: false },
        { label: '土日祝', checked: false },
      ]
    },
    {
      title: '最近の出来事で悩んでいること',
      category: 'specialty',
      inputType: 'checkbox',
      required: true,
      answers: [
        { label: 'しんどい・辛い・不安', checked: false },
        { label: '自身の病気・症状', checked: false },
        { label: '自分の性格', checked: false },
        { label: '仕事上の悩み', checked: false },
        { label: '仕事上の人間関係', checked: false },
        { label: 'プライベートの人間関係', checked: false },
        { label: '職場でのハラスメント', checked: false },
        { label: 'プライベートでのハラスメント', checked: false },
        { label: '子育て', checked: false },
        { label: '介護・看病', checked: false },
      ]
    },
    {
      title: 'ご自身に当てはまると思うもの',
      category: 'specialty',
      inputType: 'checkbox',
      required: false,
      answers: [
        { label: '不安やうつなどの症状', checked: false },
        { label: 'ストレスによる身体の不調', checked: false },
        { label: 'トラウマ', checked: false },
        { label: '依存症（アルコール、ギャンブル、スマートフォン、ゲーム など）', checked: false },
        { label: '発達障害', checked: false },
        { label: 'HSP', checked: false },
        { label: 'その他', checked: false },
      ]
    },
    {
      title: '現在のご状況',
      category: 'specialty',
      inputType: 'checkbox',
      required: false,
      answers: [
        { label:'身近な人やペットの死を経験した', checked: false },
        { label:'休職・離職中', checked: false },
        { label:'転職活動中', checked: false },
        { label:'自分・子どもが不登校状態', checked: false },
        { label:'引きこもり状態', checked: false },
        { label:'がん等の重い病気で闘病中', checked: false },
        { label:'妊娠中・出産後', checked: false },
        { label:'不妊治療中', checked: false },
      ]
    },
  ])
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0)

  const addQuestionBarNextStyle = () => {
    const currentQuestionElement = document.getElementById(`question-bar-${currentQuestionNumber + 1}`)
    if ( currentQuestionElement ) {
      currentQuestionElement.classList.add('bg-red-400')
    }
  }

  const removeQuestionBarNextStyle = () => {
    const currentQuestionElement = document.getElementById(`question-bar-${currentQuestionNumber}`)
    if ( currentQuestionElement ) {
      currentQuestionElement.classList.remove('bg-red-400')
    }
  }

  const nextQuestion = () => {
    if (!isValid()) return

    setCurrentQuestionNumber(currentQuestionNumber + 1)
    addQuestionBarNextStyle()
  }

  const isValid = () => {
    setErrorMessage(null)

    if (!questions[currentQuestionNumber].required) return true

    const checkes = questions[currentQuestionNumber].answers.filter((answer) => {
      return answer.checked
    })

    if(checkes.length <= 0) {
      setErrorMessage('回答を1つ以上選択してください。')
      return false
    }

    return true
  }

  const onChangeAnswer = (e) => {
    const newQuestions = questions.map((question, index) => {
      const newQuestion = { ...question }
      if(index !== currentQuestionNumber) return newQuestion

      if(question.inputType === 'checkbox') {
        question.answers.map((answer) => {
          if (answer.label === e.target.value) {
            answer.checked = !answer.checked
          }
        })
      } else { // radio button
        question.answers.map((answer) => {
          if (answer.label === e.target.value) {
            answer.checked = !answer.checked
          } else {
            answer.checked = false
          }
        })
      }

      return newQuestion
    })
    setQuestions(newQuestions)
  }

  const prevQuestion = () => {
    setErrorMessage(null)

    if(questions[currentQuestionNumber - 1]) {
      setCurrentQuestionNumber(currentQuestionNumber - 1)
      removeQuestionBarNextStyle()
    }
  }

  const handleSubmitAnswers = async (event) => {
    if (!isValid()) return

    document.getElementById('answers_json').value = JSON.stringify(questions)
    setModalIsOpen(true)
    document.matchingForm.submit()
  }

  return (
    <>
      <Modal
        style={{
          overlay: {
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#000000a8',
            alignItems: 'center',
            zIndex: '100'
          },
          content: {
            position: 'absolute',
            width: '80%',
            margin: '15% auto',
            padding: '0',
            inset: '0',
            background: '#f7fbfc',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '20px'
          }
        }}
        isOpen={modalIsOpen}
      >
        <div className="flex h-full items-center flex-wrap">
          <div className="text-center w-full mx-auto text-xl md:text-2xl font-bold">
            {modalText}
          </div>
        </div>
      </Modal>

      <ul className="flex justify-center mt-6 w-full rounded-xl overflow-hidden">
        { questions.map((key, index) => {
          return (
            <li id={`question-bar-${index + 1}`} key={index} className="bg-gray-300 w-full h-4"></li>
          )
        })}
      </ul>
      <div className="lg:w-4/5 mx-auto pb-12 border-b-2 border-gray-200">
        <div className="text-xl lg:text-2xl font-bold text-center mt-14">
          <span className="text-yellow-500">Q</span>. {questions[currentQuestionNumber].title}
          { questions[currentQuestionNumber].required
            ? <><br /><span class="font-bold text-red-500 text-xl ml-2">※必須</span></>
            : <><br /><span class="font-bold text-gray-500 text-xl ml-2">※任意</span></>
          }

          { questions[currentQuestionNumber].inputType === 'checkbox' &&
              <><span class="font-bold text-yellow-500 text-xl">（複数選択可)</span></>
          }
        </div>
        <ul className="mt-4 lg:mt-10 flex flex-col items-center text-center justify-center">
          { questions[currentQuestionNumber].answers.map((answer, index) => {
            return (
              <li key={index + 1} className="w-full">
                {questions[currentQuestionNumber].inputType === 'radioButton' ?
                  <>
                    <input
                      id={`answer_${currentQuestionNumber}_${index}`}
                      type="radio"
                      value={answer.label}
                      className="hidden"
                      onChange={onChangeAnswer}
                      checked={answer.checked}
                    />
                    <label htmlFor={`answer_${currentQuestionNumber}_${index}`} className="radiobutton01 w-full border-2 border-gray-300 mt-4 p-4 lg:p-6 cursor-pointer flex text-lg lg:text-xl items-center justify-center font-bold mx-2 pl-10">
                      {answer.label}
                    </label>
                  </>
                :
                  <>
                    <input
                      id={`answer_${currentQuestionNumber}_${index}`}
                      type="checkbox"
                      value={answer.label}
                      className="hidden"
                      onChange={onChangeAnswer}
                      checked={answer.checked}
                    />
                    <label htmlFor={`answer_${currentQuestionNumber}_${index}`} className="checkbox01 w-full border-2 border-gray-300 mt-4 p-4 lg:p-6 cursor-pointer flex text-lg lg:text-xl items-center justify-center font-bold mx-2 pl-10">
                      {answer.label}
                    </label>
                  </>
                }
              </li>
            )
          })}
        </ul>

        {errorMessage && <div className="font-bold mt-6 text-red-500">{errorMessage}</div>}

        {questions[currentQuestionNumber + 1] ? (
          <div className="flex items-center justify-center">
            <button onClick={nextQuestion} className="cursor-pointer mt-12 font-bold text-center text-base bg-green-500 border-b-2 border-green-600 text-white rounded-xl px-6 py-4">
              次の質問へ ▶
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <button onClick={handleSubmitAnswers} className="cursor-pointer mt-12 font-bold text-center text-base bg-yellow-500 border-b-2 border-yellow-600 text-white rounded-xl px-6 py-4">
              回答を送信 ▶
            </button>
          </div>
        )}

        {questions[currentQuestionNumber - 1] && (
          <div className="flex items-center justify-center mt-6">
            <button onClick={prevQuestion} className="cursor-pointer font-bold text-center text-base bg-green-600 border-b-2 border-green-700 text-white rounded-xl px-6 py-4">
              ◀ 前の質問へ
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default MatchingForm
