import React, { useState } from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Tokyo')

const ReserveEvents = ({ events, handleEventClick }) => {
  return (
    <>
      <ul id="reserves" className="flex flex-wrap">
        { events.map((event, index) => {
          return (
            <li className="cursor-pointer w-full md:w-1/3 mt-6 px-2" key={index} onClick={() => handleEventClick(event)}>
              <div className="border-2 rounded-xl p-4 font-bold text-center">
                <div className="text-center justify-center items-center">
                  <div className="text-sm">
                    {dayjs(event.start).tz().format('YYYY年MM月DD日')}
                  </div>
                  <div className="mt-2 text-xl">{dayjs(event.start).tz().format('HH:mm')} ~ {dayjs(event.end).tz().format('HH:mm')}</div>
                </div>
                { handleEventClick && <div className="font-bold mt-4">
                  <div className='border-green-500 border-2 rounded-lg text-center text-green-500 text-sm block w-full p-2'>
                    予約に進む
                  </div>
                </div> }
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default ReserveEvents
